import React from 'react'
import {Link} from 'gatsby'
import {Navbar, Nav, NavDropdown, Dropdown} from 'react-bootstrap';
import { globalHistory } from "@reach/router"
import '../styles/header.css'

import neurafarm from "../images/Neurafarm-Logo-Samping.png"
import logoInternet from "../images/internet.png"


/* 
<Link className = "link-container" to="/">
                                What We Do 
                                <span style = {{display : this.state.url !== `${baseUrl}we-do` ? "none" : "inline-block"}}
                                    className = "active-nav" />
                            </Link>
                            
                            <Link className = "link-container" to="/" >
                                Who We Are 
                                <span style = {{display : this.state.url !== `${baseUrl}we-are` ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>

                            <Link className = "link-container" to="/dokter-tania">
                                Dokter Tania
                                <span style = {{display : this.state.url !== `${baseUrl}dokter-tania` ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>

                            <Link className = "link-container" to="/">
                                Blog
                                <span style = {{display : this.state.url !== `${baseUrl}nav4` ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>
                            
                            <Link className = "link-container" to="/">
                                Contact Us
                                <span style = {{display : this.state.url !== `${baseUrl}nav5` ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>
*/

//const baseUrl = typeof window !== 'undefined' ? window.location.href : '';

class Header extends React.Component {

    /*constructor(props){
        super(props);
    }*/

    render(){
        return(
                <Navbar id = "navbar-container" collapseOnSelect expand="md" variant="light" fixed = "top">
                    <Navbar.Brand>
                        <Link to ="/id">
                            <img className = "neurafarm-logo" src = {neurafarm} alt = "Neurafarm"/>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Link className = "link-container" to="/id/what-we-do">
                                Tugas Kami 
                                <span style = {{display : globalHistory.location.pathname !== "/id/what-we-do" ? "none" : "inline-block"}}
                                    className = "active-nav" />
                            </Link>
                            
                            <Link className = "link-container" to="/id/who-we-are" >
                                Siapa Kami 
                                <span style = {{display : globalHistory.location.pathname !== "/id/who-we-are" ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>

                            <Link className = "link-container" to="/id/dokter-tania">
                                Dokter Tania
                                <span style = {{display : globalHistory.location.pathname !== "/id/dokter-tania" ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>

                            <Link className = "link-container" to="/blog">
                                Blog
                                <span style = {{display : globalHistory.location.pathname !== "/id/blog" ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>

                            <Link className = "link-container" to="/careers">
                                Join Us
                                <span style = {{display : globalHistory.location.pathname !== "/id/careers" ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>
                            
                            <Link className = "link-container" to="/id/contact-us">
                                Contact Us
                                <span style = {{display : globalHistory.location.pathname !== "/id/contact-us" ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>

                        </Nav>
                        <img className = "language-logo" src = {logoInternet} alt = "internet-logo" />
                        <NavDropdown title="Indonesia">                
                            <NavDropdown.Item >
                                <div className = "language-item">
                                    Indonesia                        
                                    <div className = "active-circle"></div>
                                </div>
                            </NavDropdown.Item>
                            
                            <Dropdown.Divider />
                            
                            <NavDropdown.Item ><Link to = "/" className = "language-item">English</Link></NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                </Navbar>
        )
    }
}

export default Header;