import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import '../styles/layout.css'

import Header from '../components/header-id'
import Footer from '../components/footer'

const Layout = (props) => {
    return(
        <Container fluid>
            <Header />
            <div className = "top"></div>
            {props.children}
            <Footer />
        </Container>
    )
}

export default Layout;