import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout-id'
import Head from '../../components/head'
import '../../styles/contact-us.css'
import scrollTo from 'gatsby-plugin-smoothscroll';
import Carousel from 'react-elastic-carousel'
import { OutboundLink } from "gatsby-plugin-google-analytics"
import {graphql} from 'gatsby'

import { GatsbyImage } from "gatsby-plugin-image";

import {Row, Col} from 'react-bootstrap'
import Swal from 'sweetalert2'

import Pesan from "../../images/pesan.png";
import Farming from "../../images/farming-3.png";

class ContactUs extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          submit: '',
        }
      }
  
    componentDidMount(){
          if (this.props.location.search == '?submit=True'){
              setTimeout(() => {
                  Swal.fire({
                      title: "<div class='alert-title'>Terima Kasih!</div>",
                      imageUrl: 'https://i.ibb.co/x5hFH0C/Group-5.png',
                      imageWidth: 35,
                      html:
                        "<div class='alert-text'>Pesanmu telah terkirim.<br>" +
                        "Kami akan segera kabari.<br><br></div>",
                      background: '#385723',
                      width: 450,
                      height: 300,
                      showCloseButton: true,
                      showConfirmButton: false,
                      focusConfirm: false,
                    });
              }, 0);
          } else if (this.props.location.search == '?submit=False'){
              this.setState({submit: 'false'});
          }
    }

    render(){
    
        return(
                
                <Layout>
                    <Head title = "Contact-Us"/>
                    <div id = "cu-main-container">

                        <div id = 'cu-section-container'>

                            <Col md id = "cu-col-1">

                                <img id = "cu-body-icon" src = {Pesan} alt = "pesan-img"/>

                                <div id = "cu-primary-title"> 
                                    Bicara Dengan Kami
                                </div>  

                                <div id = "sub-title">                       
                                    Kami ingin berbicara tentang bagaimana kami dapat bekerja bersama.
                                </div>

                                <div id = "description">
                                    <div>Kami percaya bahwa untuk membangun pertanian yang lebih tangguh dan berkelanjutan, kami perlu bekerja</div>
                                    <div>bersama dengan para pemimpin di seluruh rantai pasokan. Jika Anda memiliki visi ini dan</div>
                                    <div>tertarik untuk bergabung dengan komunitas inovator ini, ceritakan lebih banyak tentang diri Anda.</div>
                                </div>

                            </Col>

                            <Col id = "cu-col-2">

                                <Row id = "cu-row">

                                    <div id = "cu-form-container">
                                        
                                        <div id = "cu-form-title">
                                            Isi Form Di Bawah Ini
                                        </div>

                                        <form method="post" action="http://tania-notification-service.azurewebsites.net/website/v2/id/contact">
                                        
                                            <div id = "cu-form-section-1">
                                                
                                                <div id = "cu-form-section-1-title">Tertarik pada :</div>

                                                <Row>

                                                    <div id = "cu-checkbox-left">
                                                        
                                                        <label class="cu-checkbox-container">Dokter Tania
                                                            <input 
                                                                type="checkbox"
                                                                name="interest"
                                                                value="Dokter Tania"/>
                                                            <span class="cu-checkmark"></span>
                                                        </label>
                                                        
                                                        <label class="cu-checkbox-container">Solusi Perusahaan
                                                            <input 
                                                                type="checkbox"
                                                                name="interest"
                                                                value="Enterprise Solutions"/>
                                                            <span class="cu-checkmark"></span>
                                                        </label>
                                                        
                                                        <label class="cu-checkbox-container">Pertanian Cerdas dan Presisi
                                                            <input 
                                                                type="checkbox"
                                                                name="interest"
                                                                value="Smart/Precision Farming"/>
                                                            <span class="cu-checkmark"></span>
                                                        </label>
                                                    
                                                    </div>

                                                    <div id = "cu-checkbox-right">
                                                        
                                                        <label class="cu-checkbox-container">Media
                                                            <input 
                                                                type="checkbox"
                                                                name="interest"
                                                                value="Media"/>
                                                            <span class="cu-checkmark"></span>
                                                        </label>
                                                        
                                                        <label class="cu-checkbox-container">Pekerjaan
                                                            <input 
                                                                type="checkbox"
                                                                name="interest"
                                                                value="Employment"/>
                                                            <span class="cu-checkmark"></span>
                                                        </label>
                                                        
                                                        <label class="cu-checkbox-container">Lainnya
                                                            <input 
                                                                type="checkbox"
                                                                name="interest"
                                                                value="Other"/>
                                                            <span class="cu-checkmark"></span>
                                                        </label>
                                                    
                                                    </div>
                                                
                                                </Row>
                                            
                                            </div>

                                            <div id = "cu-form-section-2">
                                                
                                                <div id = "cu-form-container">

                                                        <input class = "cu-input-field" 
                                                            type = "text" 
                                                            name = "name"
                                                            placeholder = "       Nama Depan*"
                                                            id = "cu-form-first-name"/>
                                                        
                                                        <input class = "cu-input-field" 
                                                            type = "text" 
                                                            name = "name"
                                                            placeholder = "       Nama Belakang"
                                                            id = "cu-form-last-name"/>
                                                        
                                                        <br/>

                                                        <input class = "cu-input-field" 
                                                            type = "text" 
                                                            name = "company"
                                                            placeholder = "       Perusahaan*"
                                                            id = "cu-form-company"/>
                                                        
                                                        <input class = "cu-input-field" 
                                                            type = "phone" 
                                                            name = "phone"
                                                            placeholder = "       Nomor Handphone"
                                                            id = "cu-form-phone"/>
                                                        
                                                        <br/>
                                                        
                                                        <input class = "cu-input-field" 
                                                            type = "email" 
                                                            name = "email"
                                                            placeholder = "       Email Kerja*"
                                                            id = "cu-form-work-email"/>

                                                        <br/>
                                                        
                                                        <textarea class = "cu-input-field" 
                                                            placeholder = "       Pesan*"
                                                            name = "message"
                                                            id = "cu-form-message"/>

                                                        <br/>

                                                        <input 
                                                            type = "submit" 
                                                            value = "Submit" 
                                                            id = "cu-form-submit"/>

                                                        <br/>

                                                        {this.state.submit==='false' && <div id="error-msg">Kami tidak dapat mengirimkan pesanmu. Coba lagi nanti</div>}
                                                                                    
                                                </div>
                                        
                                            </div>

                                        </form>
                                    
                                    </div>
                                    
                                    <div id = "cu-body-img">

                                        <img id = "img-rectangle" src = {Farming} alt = "farming-img"/>

                                    </div>

                                </Row>

                            </Col>

                        </div>

                    </div>
                </Layout>

        );

    }
    
}

export default ContactUs;